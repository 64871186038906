import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Stack } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { formatDate, formatDescription } from "../utils/utils";

const MeldingenModal = ({ show, handleClose, item }) => {
  const [address, setAddress] = useState(""); // State to hold the fetched address

  // Default icon setup
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  // Function to fetch address from coordinates
  const fetchAddress = async (lat, lon) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;
    try {
      const response = await axios.get(url);
      setAddress(response.data.display_name); // Set the fetched address
    } catch (error) {
      console.error("Error fetching address:", error);
      setAddress(""); // Reset address on error
    }
  };

  // useEffect to call fetchAddress when modal is shown and coordinates are available
  useEffect(() => {
    if (show && item.lat && item.lon) {
      fetchAddress(item.lat, item.lon);
    }
  }, [show, item.lat, item.lon]);

  // Get current year and append it to the date and time string
  const currentYear = new Date().getFullYear();
  const dateTimeString = `${item.datum}-${currentYear} ${item.tijd}`;

  return (
    <Modal
      className="darkmode"
      data-bs-theme="dark"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{item.melding}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack>
          <div className="p-1">
            <div className="bold inline">Dienst: </div>
            {item.dienst}
          </div>
          <div className="p-1">
            <div className="bold inline">Datum/Tijd: </div>
            {formatDate(dateTimeString, "Europe/Amsterdam")}
          </div>
          <div className="p-1">
            <div className="bold">Melding:</div>
            {formatDescription(item.tekstmelding)}
          </div>
          {item.lat && item.lon && (
            <div className="p-1">
              <div className="bold">Kaart:</div>
              <MapContainer
                center={[item.lat, item.lon]}
                zoom={13}
                style={{ height: "200px", width: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[item.lat, item.lon]}>
                  <Popup>{address || "Loading address..."}</Popup>
                </Marker>
              </MapContainer>
            </div>
          )}
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Sluiten
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MeldingenModal;
