import React, { useState } from "react";
import axios from "axios";
import { Button, Alert, Form, Row, Col } from "react-bootstrap";
import "../styles/bugReportForm.css";

const BugReportForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [bugReport, setBugReport] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({
    variant: "success",
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const webhookUrl =
      "https://discord.com/api/webhooks/1208090120876593232/74eIO-PSAdUDDkLxBwJY9VEuFgGep2YhXJmKff40AlMm4x85pmCn46TQWnV3NeW7D9SA";

    const messageContent = `**Nieuwe bugmelding**\nNaam: ${name}\nEmail: ${email}\nBeschrijving: ${bugReport}`;

    try {
      await axios.post(
        webhookUrl,
        {
          content: messageContent,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      setAlertContent({
        variant: "success",
        message: "Bugmelding succesvol ingediend!",
      });
      setShowAlert(true);
      setName("");
      setEmail("");
      setBugReport("");
    } catch (error) {
      console.error("Fout bij het indienen van bugmelding:", error);
      setAlertContent({
        variant: "danger",
        message: "Fout bij het indienen van bugmelding.",
      });
      setShowAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <h1 className="bold">Bugmelding Indienen</h1>
      <p>Heb je tijdens het gebruik van onze applicatie een probleem of fout ontdekt? Jouw feedback is essentieel voor ons! Met deze eenvoudige formulier kun je gemakkelijk elk probleem dat je tegenkomt aan ons rapporteren. Of het nu gaat om een kleine ongemak of een grote systeemfout, we horen graag van je zodat we onze service kunnen verbeteren. Vul je naam, emailadres en een gedetailleerde beschrijving van het probleem in. Ons team zal je melding zo snel mogelijk onderzoeken en actie ondernemen om het probleem op te lossen.</p>
      {showAlert && (
        <Alert
          variant={alertContent.variant}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          {alertContent.message}
        </Alert>
      )}
      <Form onSubmit={handleSubmit} data-bs-theme="dark">
        <Row>
          <Col>
        <Form.Group className="mb-3">
          <Form.Label>Naam:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Vul je naam in"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        </Col>
        <Col>

        <Form.Group className="mb-3">
          <Form.Label>Emailadres:</Form.Label>
          <Form.Control
            type="email"
            placeholder="Vul je emailadres in"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Bug Beschrijving:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Beschrijf de bug in detail"
            value={bugReport}
            onChange={(e) => setBugReport(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? "Verzenden..." : "Verzenden"}
        </Button>
      </Form>
    </div>
  );
};

export default BugReportForm;
