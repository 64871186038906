import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RegionSelector from "../utils/RegionSelector";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useFetchData } from "../hooks/useFetchData";
import MeldingenItem from "./MeldingenItem";
import MeldingenModal from "./MeldingenModal";
import { regions, regionDetails } from "../../constants/regions";

const Meldingen = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [regionKey, setRegionKey] = useState("twente");
  const [regionName, setRegionName] = useState("Twente");
  const [disabled, setDisabled] = useState(false);
  const [countdown, setCountdown] = useState(5); // Countdown timer starts at 5 seconds
  let { region } = useParams();

  useEffect(() => {
    if (regions[region]) {
      setRegionKey(region);
      setRegionName(regions[region]);
    }
  }, [region]);

  const fetchData = useFetchData(regionKey, setData);

  useEffect(() => {
    setData([]);
    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => clearInterval(intervalId);
  }, [regionKey, fetchData]);

  useEffect(() => {
    const regionName = regionDetails[regionKey]?.name || "Twente";
    const regionDescription =
      regionDetails[regionKey]?.description ||
      regionDetails["all_regions"].description;

    document.title = `${regionName} Meldingen - P2000.me`;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", regionDescription);
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.setAttribute("name", "description");
      newMetaDescription.content = regionDescription;
      document.head.appendChild(newMetaDescription);
    }
  }, [regionKey]);

  useEffect(() => {
    let interval;
    if (disabled) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(interval);
            setDisabled(false);
            return 5; // Reset countdown after it reaches 0
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [disabled]);

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setCurrentItem(item);
    setShow(true);
  };
  const handleRegionChange = (key) => {
    setRegionKey(key);
    setRegionName(regions[key]);
  };

  // Function to manually refresh the data
  const handleRefresh = () => {
    if (!disabled) {
      setData([]); // Optional: Clear existing data before fetching new data
      fetchData();
      setDisabled(true); // Disable button to start countdown
    }
  };

  return (
    <div className="container">
      <div className="header-container">
        <h1 className="bold">P2000 Meldingen</h1>
        <div className="subheader-container">
          <RegionSelector
            currentRegion={regionName}
            onRegionChange={handleRegionChange}
          />
          <Button
            onClick={handleRefresh}
            variant="primary"
            className="refresh-button"
            disabled={disabled}
          >
            {disabled ? (
              <span>{countdown}</span>
            ) : (
              <FontAwesomeIcon icon={faRefresh} />
            )}
          </Button>
        </div>
      </div>

      <Row>
        <ul className="timeline">
          {data.map((item) => (
            <MeldingenItem key={item.id} item={item} handleShow={handleShow} />
          ))}
        </ul>
      </Row>
      {currentItem && (
        <MeldingenModal
          show={show}
          handleClose={handleClose}
          item={currentItem}
        />
      )}
    </div>
  );
};

export default Meldingen;
