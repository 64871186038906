import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { regions } from "../../constants/regions";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const RegionSelector = ({ currentRegion }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleRegionChange = (regionKey) => {
    navigate(`/meldingen/${regionKey}`); // Navigate to the selected region
  };

  return (
    <DropdownButton
      as={ButtonGroup}
      id="dropdown-regions"
      variant="secondary"
      data-bs-theme="dark"
      title={currentRegion || "Selecteer uw regio"}
      onSelect={(eventKey) => handleRegionChange(eventKey)} // Call handleRegionChange on selection
    >
      {Object.entries(regions).map(([key, name]) => (
        <Dropdown.Item key={key} eventKey={key}>
          {name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default RegionSelector;
