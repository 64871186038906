import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Meldingen from "./components/Meldingen/Meldingen";
import NavbarComponent from "./components/NavbarComponent";
import IOSInstallModal from "./components/IOSInstallModal";
import NetworkStatusWarning from "./components/utils/NetworkStatusWarning";
import "./styles/App.css";
import BugReportForm from "./components/bugReportForm";
import TraumaHelikopterMap from "./components/TraumaHelikopterMap";

let deferredPrompt;

function App() {
  const isOnline = navigator.onLine;
  const [installable, setInstallable] = useState(false);
  const [showIOSModal, setShowIOSModal] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt = e;
      setInstallable(true);
    });
  }, []);

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const isInStandaloneMode = () => {
    return "standalone" in window.navigator && window.navigator.standalone;
  };

  const handleInstallClick = (e) => {
    e.preventDefault();
    if (isIOS()) {
      // For iOS, show instructional modal
      setShowIOSModal(true);
    } else {
      // For Android, show the install prompt
      setInstallable(false);
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Handle user acceptance
        }
      });
    }
  };

  return (
    <div className="App">
      <Router>
        <NavbarComponent
          installable={installable}
          handleInstallClick={handleInstallClick}
          isIOS={isIOS}
          isInStandaloneMode={isInStandaloneMode}
        />
        <NetworkStatusWarning isOnline={isOnline} />
        <Routes>
          <Route path="/" element={<Meldingen />} />
          <Route path="/bug-report" element={<BugReportForm />} />
          <Route path="/meldingen/:region" element={<Meldingen />} />
          <Route path="/traumahelikopterkaart" element={<TraumaHelikopterMap/>} />
        </Routes>
      </Router>
      <IOSInstallModal
        showIOSModal={showIOSModal}
        setShowIOSModal={setShowIOSModal}
      />
    </div>
  );
}

export default App;
