import React, { useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  Offcanvas,
  NavDropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { regions } from "../constants/regions";

const NavbarComponent = ({
  installable,
  handleInstallClick,
  isIOS,
  isInStandaloneMode,
}) => {
  const [showOffcanvas, setShowOffcanvas] = useState(false); // Step 2

  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  return (
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          bg="primary"
          variant="dark"
          className="mb-1"
        >
          <Container>
            <Navbar.Brand className="bold" href="/">
              <img
                alt="Logo"
                src={`${process.env.PUBLIC_URL}/images/logo-trans.png`}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{" "}
              P2000 NederlandS
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={handleShowOffcanvas}
            />
            <Navbar.Offcanvas
              show={showOffcanvas} // Step 3
              onHide={handleCloseOffcanvas} // Step 4
              className="darkmode"
              data-bs-theme="dark"
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  P2000.me Versie 1.5
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link as={Link} to="/" onClick={handleCloseOffcanvas}>
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/bug-report"
                    onClick={handleCloseOffcanvas}
                  >
                    Meld een bug
                  </Nav.Link>
                  {installable || (isIOS() && !isInStandaloneMode()) ? (
                    <Nav.Link onClick={handleInstallClick} href="#install">
                      Installeer App
                    </Nav.Link>
                  ) : null}
                  {/* Regions Dropdown */}
                  <NavDropdown
                    title="Regio's"
                    id="nav-dropdown-regions"
                    menuVariant="darkmode"
                  >
                    {Object.entries(regions).map(([key, name]) => (
                      <NavDropdown.Item
                        key={key}
                        as={Link}
                        to={`/meldingen/${key}`}
                        onClick={handleCloseOffcanvas}
                      >
                        {name}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default NavbarComponent;
