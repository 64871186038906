import { useCallback } from "react";
import axios from "axios";
import { regionIds } from "../../constants/regions";

export const useFetchData = (regionKey, setData) => {
  return useCallback(async () => {
    try {
      const regionId = regionIds[regionKey] || 1; // Default to region ID 1 (Amsterdam-Amstelland) if regionKey is not found

      const filter = {
        regios: [regionId], // Use the region ID for filtering
      };

      const response = await axios.get(
        `/api2/find/${encodeURIComponent(JSON.stringify(filter))}`
      );

      // Ensure the response contains the 'meldingen' array
      if (response.data && Array.isArray(response.data.meldingen)) {
        const filteredData = response.data.meldingen.filter((item) => {
          // Get the current year
          const currentYear = new Date().getFullYear();

          // Combine datum and tijd and append the current year for proper parsing
          const itemDateTimeString = `${currentYear}-${item.datum
            .split("-")
            .reverse()
            .join("-")}T${item.tijd}`;
          const itemDateTime = new Date(itemDateTimeString);

          const currentTime = new Date();
          const timeDifference = Math.abs(currentTime - itemDateTime);
          const hoursDifference = Math.floor(timeDifference / 3600000); // 3600000 milliseconds in an hour

          // Filter items that occurred within the last 24 hours
          return hoursDifference <= 24;
        });

        setData(filteredData);
      } else {
        setData([]); // Clear data if no valid 'meldingen' are found
      }
    } catch (error) {
      setData([]); // Clear data if there's an error
    }
  }, [regionKey, setData]);
};
