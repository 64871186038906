export const regions = {
  all_regions: "Alle regio's",
  groningen: "Groningen",
  friesland: "Friesland",
  drenthe: "Drenthe",
  ijsselland: "Ijsselland",
  twente: "Twente",
  noord_en_oost_gelderland: "Noord & Oost Gelderland",
  gelderland_midden: "Gelderland Midden",
  gelderland_zuid: "Gelderland Zuid",
  utrecht: "Utrecht",
  noord_holland_noord: "Noord Holland Noord",
  zaanstreek_waterland: "Zaanstreek Waterland",
  kennemerland: "Kennemerland",
  amsterdam_amstelland: "Amsterdam Amstelland",
  gooi_en_vechtstreek: "Gooi & Vechtstreek",
  haaglanden: "Haaglanden",
  hollands_midden: "Hollands Midden",
  rotterdam_rijnmond: "Rotterdam Rijnmond",
  zuid_holland_zuid: "Zuid Holland Zuid",
  zeeland: "Zeeland",
  midden_en_west_brabant: "Midden & West Brabant",
  brabant_noord: "Brabant Noord",
  brabant_zuid_en_oost: "Brabant Zuid & Oost",
  limburg_noord: "Limburg Noord",
  limburg_zuid: "Limburg Zuid",
  flevoland: "Flevoland",
};

export const regionDetails = {
  all_regions: {
    name: "Alle regio's",
    description:
      "Ontdek actuele P2000-meldingen en noodgevallen in heel Nederland. Blijf op de hoogte van gebeurtenissen in alle regio's.",
  },
  groningen: {
    name: "Groningen",
    description:
      "Volg de laatste P2000-meldingen in Groningen, inclusief updates uit de stad Groningen, Hoogezand en Delfzijl. Altijd geïnformeerd.",
  },
  friesland: {
    name: "Friesland",
    description:
      "Bekijk real-time P2000-alerts in Friesland. Updates uit Leeuwarden, Drachten en Sneek. Blijf op de hoogte van lokale gebeurtenissen.",
  },
  drenthe: {
    name: "Drenthe",
    description:
      "Actuele P2000-meldingen in Drenthe, met nieuws uit Assen, Emmen en Hoogeveen. Mis niets van noodgevallen en andere belangrijke informatie.",
  },
  ijsselland: {
    name: "Ijsselland",
    description:
      "Laatste P2000-updates in IJsselland. Informatie uit Zwolle, Deventer en Kampen. Volg noodgevallen en alerts in real-time.",
  },
  twente: {
    name: "Twente",
    description:
      "P2000-meldingen uit Twente direct bijgewerkt. Blijf geïnformeerd met nieuws uit Enschede, Hengelo en Almelo.",
  },
  noord_en_oost_gelderland: {
    name: "Noord & Oost Gelderland",
    description:
      "Nieuwste P2000-alerts in Noord & Oost Gelderland. Updates uit Apeldoorn, Zutphen en Harderwijk. Altijd actueel.",
  },
  gelderland_midden: {
    name: "Gelderland Midden",
    description:
      "Volg P2000-meldingen in Gelderland Midden. Informatie uit Arnhem, Ede en Doetinchem. Blijf op de hoogte van urgente gebeurtenissen.",
  },
  gelderland_zuid: {
    name: "Gelderland Zuid",
    description:
      "Ontdek P2000-noodoproepen in Gelderland Zuid. Updates uit Nijmegen, Tiel en Wijchen. Altijd actuele informatie.",
  },
  utrecht: {
    name: "Utrecht",
    description:
      "Real-time P2000-alerts in Utrecht, inclusief updates uit de stad Utrecht, Amersfoort en Veenendaal. Blijf geïnformeerd over lokale gebeurtenissen.",
  },
  noord_holland_noord: {
    name: "Noord Holland Noord",
    description:
      "Laatste P2000-meldingen in Noord Holland Noord. Actuele informatie uit Alkmaar, Den Helder en Hoorn.",
  },
  zaanstreek_waterland: {
    name: "Zaanstreek Waterland",
    description:
      "Blijf op de hoogte van P2000-meldingen in Zaanstreek Waterland. Updates uit Zaandam, Purmerend en Volendam.",
  },
  kennemerland: {
    name: "Kennemerland",
    description:
      "Volg P2000-noodoproepen in Kennemerland. Real-time informatie uit Haarlem, Velsen en Beverwijk.",
  },
  amsterdam_amstelland: {
    name: "Amsterdam Amstelland",
    description:
      "Actuele P2000-meldingen in Amsterdam Amstelland. Blijf geïnformeerd met nieuws uit Amsterdam, Amstelveen en Diemen.",
  },
  gooi_en_vechtstreek: {
    name: "Gooi & Vechtstreek",
    description:
      "Ontvang de laatste P2000-updates in Gooi & Vechtstreek. Informatie uit Hilversum, Bussum en Weesp.",
  },
  haaglanden: {
    name: "Haaglanden",
    description:
      "Volg P2000-meldingen in Haaglanden. Actuele alerts uit Den Haag, Zoetermeer en Delft.",
  },
  hollands_midden: {
    name: "Hollands Midden",
    description:
      "Real-time P2000-alerts in Hollands Midden, met updates uit Leiden, Gouda en Alphen aan den Rijn.",
  },
  rotterdam_rijnmond: {
    name: "Rotterdam Rijnmond",
    description:
      "Laatste P2000-nieuws in Rotterdam Rijnmond. Blijf op de hoogte van situaties in Rotterdam, Schiedam en Vlaardingen.",
  },
  zuid_holland_zuid: {
    name: "Zuid Holland Zuid",
    description:
      "Actuele P2000-informatie in Zuid Holland Zuid. Updates uit Dordrecht, Leiden en Spijkenisse.",
  },
  zeeland: {
    name: "Zeeland",
    description:
      "Volg P2000-updates in Zeeland. Informatie uit Middelburg, Vlissingen en Goes. Altijd actueel.",
  },
  midden_en_west_brabant: {
    name: "Midden & West Brabant",
    description:
      "Ontvang de nieuwste P2000-alerts in Midden & West Brabant. Updates uit Tilburg, Breda en Roosendaal.",
  },
  brabant_noord: {
    name: "Brabant Noord",
    description:
      "P2000-meldingen in Brabant Noord direct bijgewerkt. Blijf geïnformeerd met nieuws uit 's-Hertogenbosch, Oss en Uden.",
  },
  brabant_zuid_en_oost: {
    name: "Brabant Zuid & Oost",
    description:
      "Laatste P2000-noodoproepen in Brabant Zuid & Oost. Actuele informatie uit Eindhoven, Helmond en Veldhoven.",
  },
  limburg_noord: {
    name: "Limburg Noord",
    description:
      "Volg real-time P2000-meldingen in Limburg Noord. Updates uit Venlo, Weert en Roermond.",
  },
  limburg_zuid: {
    name: "Limburg Zuid",
    description:
      "Blijf op de hoogte van P2000-alerts in Limburg Zuid. Informatie uit Maastricht, Heerlen en Sittard.",
  },
  flevoland: {
    name: "Flevoland",
    description:
      "Actuele P2000-meldingen in Flevoland, met updates uit Almere, Lelystad en Dronten. Mis niets van belangrijke gebeurtenissen.",
  },
};

export const regionIds = {
  all_regions: 0,
  groningen: 2,
  friesland: 7,
  drenthe: 12,
  ijsselland: 17,
  twente: 23,
  noord_en_oost_gelderland: 3,
  gelderland_midden: 8,
  gelderland_zuid: 13,
  utrecht: 18,
  noord_holland_noord: 24,
  zaanstreek_waterland: 4,
  kennemerland: 9,
  amsterdam_amstelland: 1,
  gooi_en_vechtstreek: 19,
  haaglanden: 25,
  hollands_midden: 5,
  rotterdam_rijnmond: 10,
  zuid_holland_zuid: 14,
  zeeland: 20,
  midden_en_west_brabant: 26,
  brabant_noord: 6,
  brabant_zuid_en_oost: 11,
  limburg_noord: 15,
  limburg_zuid: 21,
  flevoland: 27,
};
