import {
  faFire,
  faShieldAlt,
  faAmbulance,
  faQuestionCircle,
  faHelicopter,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import moment from "moment-timezone";

export const getHulpverlenerProps = (hulpverlener) => {
  switch (hulpverlener.toLowerCase()) {
    case "brandweer":
      return { className: "red", icon: faFire };
    case "politie":
      return { className: "blue", icon: faShieldAlt };
    case "ambulance":
      return { className: "green", icon: faAmbulance };
    case "lifeliner":
      return { className: "yellow", icon: faHelicopter };
    default:
      return { className: "", icon: faQuestionCircle }; // Default case if none match
  }
};

export const hoursAgo = (datum, tijd, timezone = "Europe/Amsterdam") => {
  // Combine datum and tijd into a single timestamp
  const itemDateTime = moment.tz(`${datum} ${tijd}`, "DD-MM HH:mm", timezone);
  const currentTime = moment.tz(timezone);

  // Calculate the time difference
  const timeDifference = currentTime.diff(itemDateTime);

  if (timeDifference < 60000) {
    // Less than a minute
    const seconds = Math.floor(timeDifference / 1000);
    return `${seconds} seconden geleden`;
  }

  const minutes = Math.floor(timeDifference / 60000);
  if (minutes < 60) {
    return `${minutes} minuten geleden`;
  }

  const hours = Math.floor(timeDifference / 3600000);
  return `${hours} uur geleden`;
};

export const formatDescription = (description) => {
  let isFirstNumber = true;
  return description.split(/\s+/).map((word, index) => {
    // Adjusted regex to match six or more digits
    if (/\b\d{6,}\b/.test(word)) {
      if (isFirstNumber) {
        isFirstNumber = false;
        return word + " ";
      } else {
        return (
          <React.Fragment key={index}>
            <br />
            {word + " "}
          </React.Fragment>
        );
      }
    }
    return word + " ";
  });
};

export const formatDate = (timestamp, timezone = "Europe/Amsterdam") => {
  return moment(timestamp).tz(timezone).format("DD-MM-YYYY HH:mm");
};