import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getHulpverlenerProps, hoursAgo } from "../utils/utils";

const MeldingenItem = ({ item, handleShow }) => {
  const { className, icon } = getHulpverlenerProps(item.dienst);
  return (
    <li>
      <div className="item">
        <div className="content">
          <div className={`item-head d-flex align-items-center ${className}`}>
            <FontAwesomeIcon icon={icon} />
            <span className="ms-2 hulpverlener">{item.dienst}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="time-ago bold">{hoursAgo(item.datum, item.tijd)}</div>
              <span className="melding">{item.melding}</span>
            </div>
            <Button
              variant="link"
              className="d-flex align-items-center"
              onClick={() => handleShow(item)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default MeldingenItem;
