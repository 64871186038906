import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";

const IOSInstallModal = ({ showIOSModal, setShowIOSModal }) => {
  return (
    <Modal show={showIOSModal} onHide={() => setShowIOSModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Installeer app op iOS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Om deze app op je iPhone te installeren: tik op
          <strong>
            {" "}
            <FontAwesomeIcon icon={faArrowUpFromBracket} />{" "}
          </strong>{" "}
          en vervolgens op <strong>Zet op beginscherm</strong>.
        </p>
        <div className="text-center">
          <img
            src="/images/ios_install.png"
            alt="iOS Install Instructions"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowIOSModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IOSInstallModal;
