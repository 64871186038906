import React from "react";

const NetworkStatusWarning = ({ isOnline }) => {
  if (!isOnline) {
    return (
      <div className="alert alert-warning">
        Je bent momenteel offline. Controleer uw netwerkverbinding.
      </div>
    );
  }
  return null;
};

export default NetworkStatusWarning;
